import { SnackbarProgrammatic as Snackbar } from 'buefy'

export const _surfaceSuccessMessage = (message) => {
  Snackbar.open({
    type: 'is-success',
    message,
    duration: 1500,
    position: 'is-bottom',
  })
}
export const _surfaceErrorMessage = (message) => {
  Snackbar.open({
    type: 'is-danger',
    message,
    indefinite: true,
    position: 'is-bottom',
  })
}
