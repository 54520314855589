const mutations = {
  setStaffingRolesById: (state, staffingRolesById) => {
    state.staffingRolesById = staffingRolesById
  },

  setStaffingRoles(state, staffingRoles) {
    state.staffingRoles = staffingRoles
  },
  insertStaffingRole(state, newStaffingRole) {
    state.staffingRoles.push(newStaffingRole)
  },
  updateStaffingRole(state, { id, updates }) {
    const updatedStaffingRoles = state.staffingRoles.map((entity) =>
      entity.id === id ? { ...entity, ...updates } : entity
    )

    state.staffingRoles = updatedStaffingRoles
  },
  bulkUpdateStaffingRole(state, { ids, updates }) {
    const updatedStaffingRoles = state.staffingRoles.map((entity) =>
      ids.includes(entity.id)
        ? { ...entity, ...updates.find((role) => role.id === entity.id) }
        : entity
    )

    state.staffingRoles = updatedStaffingRoles
  },
  deleteStaffingRole(state, id) {
    state.staffingRoles = state.staffingRoles.filter((entityInstance) => entityInstance.id !== id)
  },
}

export default mutations
