<template>
  <div class="card card-with-margin ratings-item">
    <h2 data-skill-name class="skill-name">
      {{ rating.skill.name }}
    </h2>
    <div class="slider">
      <label class="rating-label">
        {{ proficiencyValueToExplanationFormatter(ratingModel.proficiency) }}
      </label>
      <vue-slider
        v-model="ratingModel.proficiency"
        class="rating-slider"
        :min="1"
        :max="5"
        :marks="true"
        :tooltip-formatter="proficiencyValueToExplanationFormatter"
        :tooltip-placement="'bottom'"
        :interval="1"
        :drag-on-click="true"
        :adsorb="true"
        :height="16"
        :disabled="!canEdit"
        :lazy="true"
        data-proficiency-slider
        @change="updateRating({ proficiency: $event })"
      />
    </div>
    <div class="slider">
      <label class="rating-label">
        {{ interestValueToExplanationFormatter(ratingModel.interest) }}
      </label>
      <vue-slider
        v-model="ratingModel.interest"
        class="rating-slider"
        :min="1"
        :max="5"
        :marks="true"
        :tooltip-formatter="interestValueToExplanationFormatter"
        :tooltip-placement="'bottom'"
        :interval="1"
        :drag-on-click="true"
        :adsorb="true"
        :height="16"
        :disabled="!canEdit"
        :lazy="true"
        data-interest-slider
        @change="updateRating({ interest: $event })"
      />
    </div>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

const interestValueToTooltipStringMap = {
  1: 'Zero interest',
  2: 'Will work in it if required',
  3: 'Would like to work in it / Like working in it',
  4: 'Would love to work with it',
  5: 'Working with it is a top priority',
}

export const proficiencyValueToTooltipStringMap = {
  1: 'Zero experience',
  2: 'Dabbled in it for fun / trying to learn it',
  3: 'Worked with it, but by no means an expert',
  4: 'Can contribute meaningfully without guidance',
  5: 'Very experienced, & can teach best practices',
}

export default {
  components: {
    vueSlider,
  },
  props: {
    rating: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ratingModel: { ...this.rating },
      interestValueToExplanationFormatter: (v) => interestValueToTooltipStringMap[v],
      proficiencyValueToExplanationFormatter: (v) => proficiencyValueToTooltipStringMap[v],
    }
  },
  methods: {
    updateRating(params) {
      this.$store.dispatch('updateRating', {
        ...params,
        id: this.ratingModel.id,
      })
    },
  },
}
</script>

<style>
.rating-slider {
  margin: 0rem 0.5rem 3rem;
}
</style>
