<template>
  <div class="navigation-menu">
    <input
      id="navigation-header-menu"
      type="checkbox"
      class="navigation-header-hidden-checkbox"
      @click="onClick"
    />
    <label class="navigation-header-menu" for="navigation-header-menu">
      <span class="navigation-header-menu-hamburger" />
      <span class="navigation-header-menu-title">MENU</span>
    </label>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  methods: {
    onClick() {
      this.$emit('toggleMenu')
    },
  },
})
</script>

<style lang="scss">
.navigation-menu {
  z-index: 6;

  @include for-desktop-up {
    display: none;
  }
}

.navigation-header-hidden-checkbox {
  display: none;
}

.navigation-header-menu {
  color: white;
  margin-right: 15px;
  cursor: pointer;

  @include for-desktop-up {
    display: none;
  }
}

.navigation-header-menu-title {
  margin-left: 0.5rem;
  font-weight: 700;
}

.navigation-header-menu-hamburger {
  transition-duration: 0s;
  transition-delay: 0.2s;
  display: inline-block;
  top: -0.2rem;
  width: 1.2rem;
  height: 0.2rem;
  background-color: white;
  position: relative;
}

.navigation-header-menu .navigation-header-menu-hamburger:before {
  margin-top: -0.4rem;
}

.navigation-header-menu .navigation-header-menu-hamburger:after {
  margin-top: 0.4rem;
}

.navigation-header-menu .navigation-header-menu-hamburger:before,
.navigation-header-menu .navigation-header-menu-hamburger:after {
  transition-delay: 0.2s, 0s;
  transition-duration: 0.2s;
  transition: margin, transform;
  display: block;
  content: '';
  position: absolute;
  width: 1.2rem;
  height: 0.2rem;
  background-color: white;
}
</style>
