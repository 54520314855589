// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'
import App from '../src/App.vue'
import createRouter from '../src/router'
import createStore from '../src/store'
import { createAnalytics } from '../src/analytics'
import '@/filters/index'
import '@/buefy'
import 'buefy/dist/buefy.min.css'
import '@/styles/root.scss'
import Rails from '@rails/ujs'

Rails.start()

require.context('../images', true, /\.(?:png|jpg|gif|ico|svg)$/)

document.addEventListener('DOMContentLoaded', () => {
  const appEl = document.getElementById('app')
  if (appEl) {
    Vue.config.productionTip = false
    const router = createRouter()
    const store = createStore()
    const analytics = createAnalytics()
    new Vue({
      router,
      store,
      analytics,
      render: (h) => h(App),
    }).$mount(appEl)
  }
})
