const mutations = {
  setEngagementsById: (state, engagementsById) => {
    state.engagementsById = engagementsById
  },

  setEngagements(state, engagements) {
    state.engagements = engagements
  },

  insertEngagement(state, newEngagement) {
    state.engagements.push(newEngagement)

    state.engagementsById = {
      ...state.engagementsById,
      [newEngagement.id]: newEngagement,
    }
  },

  updateEngagement(state, { id, updates }) {
    const updatedEngagements = state.engagements.map((entity) =>
      entity.id === id ? { ...entity, ...updates } : entity
    )

    state.engagements = updatedEngagements

    const oldEngagement = state.engagementsById[id]
    state.engagementsById = {
      ...state.engagementsById,
      [oldEngagement.id]: {
        ...oldEngagement,
        ...updates,
      },
    }
  },

  deleteEngagement(state, id) {
    state.engagements = state.engagements.filter((entityInstance) => entityInstance.id !== id)

    const { [id]: deletedEngagement, ...engagements } = state.engagementsById
    state.engagementsById = engagements
  },
}

export default mutations
