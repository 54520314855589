export const isProduction = () => process.env.RAILS_ENV === 'production'

export const isStaging = () =>
  // This function should be a temporary solution until we can determine environment
  // from process.env
  !!window.location.host.match(/intranet-staging-stride/)

export const isDev = () => process.env.RAILS_ENV === 'development'

export const isTest = () => process.env.RAILS_ENV === 'test'
