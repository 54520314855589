import sortBy from 'lodash/sortBy'

import { ENTITIES } from '@/store'
import { proficiencyValueToTooltipStringMap as proficiencyLevelClarificationMap } from '@/components/Rating.vue'

const INTEGER_REPRESENTING_NO_EXPERIENCE = 1

const userGetters = {
  getFilteredUsersByNameSearch: (_, getters) => (searchText) => {
    if (!searchText) return []

    let filteredUsers = []
    for (const id in getters.activeUsers) {
      const user = getters.activeUsers[id]
      const downcasedSearchString = searchText.toLowerCase()
      if (user.name.toLowerCase().includes(downcasedSearchString)) {
        filteredUsers = [...filteredUsers, user]
      }
    }

    return sortBy(filteredUsers, ['name'])
  },

  getFilteredConsultantsByNameSearch: (_, getters) => (searchText) => {
    if (!searchText) return []

    let filteredConsultants = []
    for (const id in getters.consultants) {
      const consultant = getters.consultants[id]
      const downcasedSearchString = searchText.toLowerCase()
      if (consultant.name.toLowerCase().includes(downcasedSearchString)) {
        filteredConsultants = [...filteredConsultants, consultant]
      }
    }

    return sortBy(filteredConsultants, ['name'])
  },

  getUsersByIds: (state, _) => (userIds) => userIds.map((id) => state.users[id]),

  getSalesLeadUserNamesByIds: (_, getters) => (salesLeadUserIds) =>
    getters.getUsersByIds(salesLeadUserIds).map((salesLead) => salesLead.name),

  getDecoratedActiveUsersForTeamView: (_, getters) => (skillId) => {
    let decoratedUsers = []
    for (const id in getters.activeUsers) {
      const originalUser = getters.activeUsers[id]
      const user = { ...originalUser }
      const proficiency = getters.getRatingProficiencyForSkillIdAndUserId(skillId, user.id)
      user.managerDisplayString = getters.getManagerNameByConsultant(user)
      user.htmlImgString = `<img class="user-image" alt="${user.name}" title="${user.name}" src="${user.imageUrl}" />`
      user.proficiencyInSelectedSkill = proficiency
      user.proficiencyInSelectedSkillDisplayString = `${proficiency} - ${proficiencyLevelClarificationMap[proficiency]}`
      user.tierDisplayString = `${user.tier == null ? '' : user.tier}`
      user.utilizationDisplayString = `${user.percentageUtilization}%`
      user.currentClientDisplayString = getters.getCurrentClientNameByClientId(user.currentClientId)

      decoratedUsers = [...decoratedUsers, user]
    }

    if (!skillId) return decoratedUsers

    return decoratedUsers.filter(
      (user) => user.proficiencyInSelectedSkill > INTEGER_REPRESENTING_NO_EXPERIENCE
    )
  },

  getDecoratedArchivedUsersForTeamView: (_, getters) => {
    let decoratedUsers = []
    for (const id in getters.archivedUsers) {
      const originalUser = getters.archivedUsers[id]
      const user = { ...originalUser }
      user.htmlImgString = `<img class="user-image" alt="${user.name}" title="${user.name}" src="${user.imageUrl}" />`
      user.tierDisplayString = `${user.tier == null ? '' : user.tier}`
      user.utilizationDisplayString = `${user.percentageUtilization}%`
      user.currentClientDisplayString = getters.getCurrentClientNameByClientId(user.currentClientId)
      decoratedUsers = [...decoratedUsers, user]
    }
    return decoratedUsers
  },

  getAllManagers: (_, getters) => {
    const currentPlacedStaffingRoles = getters.currentPlacedStaffingRoles

    const uniqueManagerIds = currentPlacedStaffingRoles.reduce((accumulator, role) => {
      if (role.managerId && !accumulator.includes(role.managerId)) {
        accumulator.push(role.managerId)
      }
      return accumulator
    }, [])

    return getters.getUsersByIds(uniqueManagerIds)
  },

  getManagerNameByConsultant: (_, getters) => (consultant) => {
    const currentPlacedStaffingRole = getters.getCurrentPlacedStaffingRoleByConsultantId(
      consultant.id
    )

    if (!currentPlacedStaffingRole) {
      return 'Bench Manager'
    }
    if (!currentPlacedStaffingRole.managerId) {
      return null
    }

    const manager = getters.getEntityById(ENTITIES.USERS, currentPlacedStaffingRole.managerId)

    return manager ? manager.name : null
  },

  getTitles: (state, _) => () => state.titles || [],
}

export default userGetters
