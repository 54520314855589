const mutations = {
  setRatings(state, ratings) {
    state.ratings = ratings
  },
  updateRating(state, { id, updates }) {
    const updatedRatings = state.ratings.map((rating) =>
      rating.id === id ? { ...rating, ...updates } : rating
    )

    state.ratings = updatedRatings
  },
}

export default mutations
