import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const store = {
  actions,
  getters,
  mutations,
}

export default store
