import api from '@/api'
import { STAFFING_ROLES } from '@/store/constants.js'
import { _surfaceSuccessMessage, _surfaceErrorMessage } from '@/snackbar'

const actions = {
  async saveClient({ commit }, data) {
    try {
      const newClient = await api.saveClient(data)

      commit('insertClient', newClient)

      _surfaceSuccessMessage('Added!')
    } catch {
      _surfaceErrorMessage('Creation failed. Please try again.')
    }
  },

  async updateClient({ commit }, data) {
    try {
      const updatedClient = await api.updateClient(data)
      commit('updateClient', {
        id: updatedClient.id,
        updates: updatedClient,
      })

      _surfaceSuccessMessage('Updated!')
    } catch {
      _surfaceErrorMessage('Update failed! Please try again.')
    }
  },

  fetchClientsById: async ({ commit }) => commit('setClientsById', await api.fetchClientsById()),

  async fetchClients({ commit }) {
    const clients = await api.fetchClients()

    const transformedClientsObject = clients.reduce(
      (accumulator, client) => {
        const { engagements, ...clientInfo } = client
        accumulator.clients.push(clientInfo)

        engagements.forEach((engagement) => {
          const { staffingRoles, ...engagementInfo } = engagement
          accumulator.engagements.push(engagementInfo)

          staffingRoles.forEach((staffingRole) => {
            const { placements, ...staffingRoleInfo } = staffingRole
            accumulator.staffingRoles.push(staffingRoleInfo)
            placements.forEach((p) => {
              accumulator.placements.push(p)
            })
          })
        })
        return accumulator
      },
      {
        clients: [],
        engagements: [],
        staffingRoles: [],
        placements: [],
      }
    )

    commit('setClients', transformedClientsObject.clients)
    commit('setEngagements', transformedClientsObject.engagements)
    commit('set', {
      entityName: STAFFING_ROLES,
      value: transformedClientsObject.staffingRoles,
    })
    commit('setPlacements', transformedClientsObject.placements)
  },

  fetchActiveClientsById: async ({ commit }) =>
    commit('setActiveClientsById', await api.fetchActiveClientsById()),
}

export default actions
