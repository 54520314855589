const mutations = {
  setPlacements(state, placements) {
    state.placements = placements
  },
  insertPlacement(state, newPlacement) {
    state.placements.push(newPlacement)
  },
  removePlacements(state, staffingRoleId) {
    state.placements = state.placements.filter(
      (placement) => placement.staffingRoleId !== staffingRoleId
    )
  },
}

export default mutations
