import * as Environment from '@/helpers/environmentHelper'
import Vue from 'vue'

class HeapService {
  constructor(appId) {
    this.appId = appId
    if (!window.heap) {
      // prettier-ignore
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    }
    window.heap.load(this.appId)
  }

  identify(currentUser) {
    window.heap.addUserProperties({
      Title: currentUser.title,
      Admin: currentUser.admin,
      'Employee Type': currentUser.employeeType,
    })
  }
}

class TestHeapService {
  constructor() {}
  identify(currentUser) {}
}

const HEAP_PROJECT_ID_BY_ENV = {
  local: '4172608257',
  staging: '3587834579',
  production: '1964424719',
}

const appId = () => {
  const { local, staging, production } = HEAP_PROJECT_ID_BY_ENV
  if (Environment.isDev()) {
    return local
  }
  if (Environment.isStaging()) {
    return staging
  }

  return production
}

const createAnalytics = () => {
  if (!Environment.isTest()) {
    return new HeapService(appId())
  } else {
    return new TestHeapService()
  }
}

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.analytics) {
      this.$analytics = options.analytics
    } else if (options.parent && options.parent.$analytics) {
      this.$analytics = options.parent.$analytics
    }
  },
})

export { HeapService, TestHeapService, createAnalytics }
