<template>
  <div v-if="currentUser" class="main">
    <Navigation class="navigation" />

    <div class="body">
      <Header class="header" />
      <router-view class="content" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from '@/components/TheNavigation.vue'
import Header from '@/components/TheHeader.vue'

export default {
  components: {
    Navigation,
    Header,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  created() {
    this.$store.dispatch('fetchUsers')
  },
}
</script>

<style lang="scss">
.main {
  width: 100%;
  height: 100%;
}

.navigation {
  width: 100%;
  height: $navigation-height;
  float: left;

  @include for-desktop-up {
    width: $navigation-width-desktop;
    height: 100%;
  }
}

.body {
  width: 100%;
  height: calc(100% - #{$navigation-height});
  float: left;

  @include for-desktop-up {
    width: calc(100% - #{$navigation-width-desktop});
    height: 100%;
  }
}

.header {
  display: none;

  @include for-desktop-up {
    display: block;
    width: 100%;
    height: $header-height;
  }
}

.content {
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow: scroll;

  @include for-desktop-up {
    height: calc(100% - #{$header-height}) !important;
    padding: 2rem;
  }
}
</style>
