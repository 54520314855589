import api from '@/api'
import { _surfaceErrorMessage, _surfaceSuccessMessage } from '@/snackbar'

const actions = {
  async updateUser({ commit, _ }, payload) {
    try {
      const { ...user } = await api.updateUser(payload)

      commit('updateUser', {
        id: user.id,
        updates: user,
      })

      _surfaceSuccessMessage('User updated!')
    } catch (payload) {
      const { error } = payload.data
      _surfaceErrorMessage(`User not updated: ${error}`)
    }
  },

  async createUser({ commit, _ }, payload) {
    try {
      const { ...user } = await api.createUser(payload)

      commit('insertUser', user)

      _surfaceSuccessMessage('User created!')
    } catch (errResponse) {
      const { error } = errResponse.data
      _surfaceErrorMessage(`User not created: ${error}`)
    }
  },

  async fetchTitles({ commit }) {
    const titles = await api.fetchTitles()

    commit('setTitles', titles)
  },
}

export default actions
