import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const store = {
  getters,
  mutations,
  actions,
}
export default store
