import api from '@/api'

const actions = {
  async fetchSponsorships({ commit }) {
    const sponsorships = await api.fetchSponsorships()
    commit('setSponsorships', sponsorships)
  },

  async saveNewSponsorship({ dispatch }, matchDetails) {
    await api.saveSponsorship(matchDetails)
    dispatch('fetchSponsorships')
  },

  async updateSponsorship({ dispatch }, matchDetails) {
    await api.updateSponsorship(matchDetails)
    dispatch('fetchSponsorships')
  },

  async deleteSponsorship({ dispatch }, matchDetails) {
    await api.deleteSponsorship(matchDetails)
    dispatch('fetchSponsorships')
  },
}

export default actions
