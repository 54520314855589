import api from '@/api'

const actions = {
  async fetchFeatures({ commit }) {
    const response = await api.fetchFeatures()

    response.features.forEach(({ key, state }) => {
      commit('setFeatureByKey', { key, enabled: state === 'on' })
    })
  },
}

export default actions
