<template>
  <nav>
    <header class="navigation-header">
      <img alt="Stride Logo" :src="logo" data-logo />

      <NavigationHamburgerMenu @toggleMenu="toggleMobileMenu" />
    </header>

    <input id="navigation-links-menu" type="checkbox" class="navigation-links-hidden-checkbox" />
    <div class="navigation-links" for="navigation-links-menu">
      <router-link to="/availability" class="navigation-link">
        <i class="fas fa-calendar fa-fw navigation-link-icon" />
        <span class="navigation-link-title">Availability</span>
      </router-link>

      <router-link to="/staffing" class="navigation-link">
        <i class="fas fa-users fa-fw navigation-link-icon" />
        <span class="navigation-link-title"> Staffing </span>
      </router-link>

      <router-link to="/clients" class="navigation-link">
        <i class="fas fa-user-tie fa-fw navigation-link-icon" />
        <span class="navigation-link-title"> Clients </span>
      </router-link>

      <router-link to="/team" class="navigation-link">
        <i class="fas fa-id-card fa-fw navigation-link-icon" />
        <span class="navigation-link-title">Team</span>
      </router-link>

      <div class="navigation-gap"></div>

      <div class="navigation-footer">
        <button
          v-if="isStaging"
          class="admin-indicator"
          data-admin-indicator
          @click="onToggleAdmin"
        >
          <span v-if="isAdmin">Admin View</span>
          <span v-else>Non-Admin View</span>
        </button>
        <div v-else-if="isAdmin" class="admin-indicator" data-admin-indicator>
          <span>You're an Admin</span>
        </div>

        <div class="navigation-footer-links" data-logout @click="onLogout">
          <i class="fas fa-sign-out-alt logout-icon" />
          <span class="logout-text">Logout</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import NavigationHamburgerMenu from '@/components/NavigationHamburgerMenu.vue'
import Logo from '@/assets/logo.png'
import * as Environment from '@/helpers/environmentHelper'

export default Vue.extend({
  components: {
    NavigationHamburgerMenu,
  },
  data() {
    return {
      logo: Logo,
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isStaging: () => Environment.isStaging(),
  },
  watch: {
    $route() {
      this.toggleMobileMenu(true)
    },
  },
  methods: {
    toggleMobileMenu(off) {
      const menu = document.getElementById('navigation-links-menu')
      if (menu) {
        if (off) {
          menu.checked = false
        } else {
          menu.checked = !menu.checked
        }
      }
    },

    async onLogout() {
      await this.$store.dispatch('logout')
    },
    async onToggleAdmin() {
      await this.$store.dispatch('toggleAdmin')
    },
  },
})
</script>

<style scoped lang="scss">
nav {
  background-color: $dark-green;

  @include for-desktop-up {
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  }
}

.navigation-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  @include for-desktop-up {
    height: $header-height;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-dark;
  }
}

.navigation-links-hidden-checkbox {
  display: none;
}

.navigation-links {
  display: none;
  overflow: auto;

  @include for-desktop-up {
    display: flex;
    flex-direction: column;
    height: calc(100% - 55px);
    opacity: 1;
    background-color: $dark-green;

    position: static;
    top: auto;
    left: auto;
    z-index: 0;
  }
}

.navigation-links::before,
.navigation-links::after {
  content: '';
  margin: auto;

  @include for-desktop-up {
    content: none;
  }
}

.navigation-gap {
  @include for-desktop-up {
    flex: 1;
  }
}

.navigation-footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up {
    width: $navigation-width-desktop;
  }
}

.navigation-footer-links {
  .logout-text {
    margin-top: 4rem;
    display: block;
    color: $cream;
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
  }

  .logout-icon {
    display: none;
  }

  @include for-desktop-up {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-top: 1px solid $dark-green;
    background-color: $dark-green;
    cursor: pointer;
    color: white;

    .logout-icon {
      display: inline-block;
    }

    .logout-text {
      display: none;
    }
  }

  &:hover {
    color: $mint-green;
  }
}

.navigation-links-hidden-checkbox:checked ~ .navigation-links {
  @include for-everything-but-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    background-color: $dark-green;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

.navigation-link {
  display: block;
  cursor: pointer;
  text-decoration: none;
  line-height: 42px;
  @include for-everything-but-desktop {
    margin-bottom: 1rem;
  }

  @include for-desktop-up {
    width: 100%;
    padding: 12px 0 12px 25px;
    border-bottom: 0.25px solid #eaeaea;
  }
}

.navigation-link:hover {
  color: $dark-green;
  background-color: $mint-green;
  @include for-everything-but-desktop {
    .navigation-link-title {
      color: $dark-green;
      background-color: $mint-green;
    }
  }

  @include for-desktop-up {
    .navigation-link-title,
    .navigation-link-icon {
      color: $dark-green;
    }
  }
}

.navigation-link.router-link-active {
  background-color: $mint-green;

  @include for-everything-but-desktop {
    .navigation-link-title {
      color: $dark-green;
      background-color: $mint-green;
    }
  }

  @include for-desktop-up {
    .navigation-link-title,
    .navigation-link-icon {
      color: $dark-green;
      font-weight: 700;
    }
  }
}

.navigation-link-icon {
  display: none;

  @include for-desktop-up {
    display: inline-block;
    font-size: 19px;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 11px;
    color: $cream;
  }
}

.navigation-link-title {
  position: relative;
  color: $cream;
  font-size: 25px;
  font-weight: 700;

  @include for-desktop-up {
    font-size: 16px;
    font-weight: 500;
    color: $cream;
  }
}

.admin-indicator {
  background-color: #e7040f;
  border-radius: 0.5rem;
  border-style: unset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: white;
  font-weight: 700;
  height: 49px;
  margin: 1rem;
  opacity: 0.8;
  padding: 1rem;
  text-align: center;
  width: 187px;
}

img {
  max-height: 3.5rem !important;
  max-width: 3.5rem !important;

  @include for-desktop-up {
    max-height: 2.5rem !important;
    max-width: 2.5rem !important;
  }
}
</style>
