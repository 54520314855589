import api from '@/api'
import { _surfaceErrorMessage, _surfaceSuccessMessage } from '@/snackbar'

const actions = {
  async fetchRatings({ commit }) {
    const ratings = await api.fetchRatings()
    commit('setRatings', ratings)
  },
  async updateRating({ commit }, payload) {
    try {
      const rating = await api.updateRating(payload)
      commit('updateRating', {
        id: rating.id,
        updates: rating,
      })

      _surfaceSuccessMessage('Update succeeded!')
    } catch {
      _surfaceErrorMessage('Update failed! Please try again later.')
    }
  },
}

export default actions
