export const STAFFING_ROLES = 'staffingRoles'
export const RATINGS = 'ratings'
export const SKILLS = 'skills'
export const ENGAGEMENTS = 'engagements'
export const PLACEMENTS = 'placements'
export const SPONSORSHIPS = 'sponsorships'
export const SPONSORS = 'sponsors'
export const USERS = 'users'

export const PLACED = 'Placed'
export const PROPOSED = 'Proposed'

export const SQL = 'SQL'
export const LEAD = 'Lead'
export const SALES_QUALIFIED_LEAD = 'SQL (Sales Qualified Lead)'
export const DISQUALIFIED = 'Disqualified'
export const SOLUTION_DESIGN = 'Solution Design'
export const PROPOSAL = 'Proposal'
export const CONTRACTING = 'Contracting'
export const CLOSED_WON = 'Closed - Won'
export const CLOSED_LOST = 'Closed - Lost'
export const WON = 'Won'

export const ACTIVE_ENGAGEMENT_STATUSES = [
  SQL,
  LEAD,
  SALES_QUALIFIED_LEAD,
  SOLUTION_DESIGN,
  PROPOSAL,
  CONTRACTING,
  CLOSED_WON,
  WON,
]

export const CLOSED_WON_ENGAGEMENT_STATUSES = ['Closed - Won', 'Won']
export const EMPLOYEE_TYPE_EMPLOYEE = 'Employee'
export const EMPLOYEE_TYPE_PARTNER = 'Partner'
export const EMPLOYEE_ACCESS_OPTIONS = ['user', 'admin', 'third party']
