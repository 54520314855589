import dayjs from 'dayjs'
import inRange from 'lodash/inRange'
import unionBy from 'lodash/unionBy'
import maxBy from 'lodash/maxBy'

import { formatDate } from '@/filters/dateFormatter'
import { NOT_PROPOSED, PLACEMENT_STATUSES } from '@/store'

export const sortedPlacements = (usersWithPlacements) =>
  usersWithPlacements.sort(
    (userA, userB) => compareByStatus(userA, userB) || compareByDateDescending(userA, userB)
  )

const compareByStatus = (userA, userB) =>
  PLACEMENT_STATUSES.indexOf(userB.status) - PLACEMENT_STATUSES.indexOf(userA.status)
const compareByDateDescending = (userA, userB) =>
  dayjs(userA.nextAvailableDate).isAfter(dayjs(userB.nextAvailableDate)) ? 1 : -1

const buildPlacement = (id, staffingRoleId, status, user) => {
  if (!user) return
  return {
    id,
    staffingRoleId: staffingRoleId,
    status,
    userId: user.id,
    userName: user.name,
    userTier: user.tier,
    userPercentageUtilization: user.percentageUtilization,
    nextAvailableDate: formatDate(user.nextAvailableDate),
  }
}

const getters = {
  consultantsEligibleForStaffingRole: (_, getters) => (staffingRole) => {
    const ACCEPTABLE_TIER_DELTA = 1
    const lowerBound = staffingRole.tier - ACCEPTABLE_TIER_DELTA
    const upperBoundInclusive = staffingRole.tier + ACCEPTABLE_TIER_DELTA + 1

    return getters.consultantsWithNextAvailableDate.filter(
      (consultant) =>
        !(consultant.percentageUtilization === 0) &&
        inRange(consultant.tier, lowerBound, upperBoundInclusive)
    )
  },

  getPlacementsForStaffingRole: (state) => (staffingRoleId) =>
    state.placements.filter((placement) => placement.staffingRoleId === staffingRoleId),

  getPlacedUsersForStaffingRole: (state, getters) => (staffingRole) => {
    const placements = getters.getPlacementsForStaffingRole(staffingRole.id)
    const placedUserIds = placements.map(({ userId }) => userId)

    let users = []
    if (state.users) {
      for (const id in state.users) {
        const user = state.users[id]
        if (placedUserIds.includes(parseInt(id))) {
          users = [...users, user]
        }
      }
    }

    return users
  },

  getMostAdvancedPlacementStatusForStaffingRole: (_, getters) => (staffingRole) => {
    if (!staffingRole || !staffingRole.id) return PLACEMENT_STATUSES[0]
    const placements = getters.getPlacementsForStaffingRole(staffingRole.id)
    return placements.length > 0
      ? maxBy(placements, (placement) => PLACEMENT_STATUSES.indexOf(placement.status)).status
      : PLACEMENT_STATUSES[0]
  },

  buildAllPossiblePlacementsForStaffingRole: (state, getters) => (staffingRole) => {
    const eligibleConsultants = getters.consultantsEligibleForStaffingRole(staffingRole)
    const allPlacementsForStaffingRole = getters.getPlacementsForStaffingRole(staffingRole.id)

    const notProposedPlacements = eligibleConsultants.map((consultant) =>
      buildPlacement(null, staffingRole.id, NOT_PROPOSED, consultant)
    )

    const persistedPlacements = allPlacementsForStaffingRole
      .map((placement) => {
        const user = state.users[placement.userId]
        return buildPlacement(placement.id, staffingRole.id, placement.status, user)
      })
      .filter((placement) => !!placement)

    return sortedPlacements(unionBy(persistedPlacements, notProposedPlacements, 'userId'))
  },
}

export default getters
