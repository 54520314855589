import api from '@/api'

const actions = {
  async fetchSkills({ commit }) {
    const skills = await api.fetchSkills()
    commit('setSkills', skills)
  },
}

export default actions
