import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { nextWeekdayAfter } from '@/helpers/dateHelper'
import { _surfaceErrorMessage, _surfaceSuccessMessage } from '@/snackbar'
import '@/filters'

import clients from '@/store/clients'
import engagements from '@/store/engagements'
import features from '@/store/features'
import placements from '@/store/placements'
import ratings from '@/store/ratings'
import staffingRoles from '@/store/staffingRoles'
import skills from '@/store/skills'
import sponsors from '@/store/sponsors'
import sponsorships from '@/store/sponsorships'
import users from '@/store/users'

import {
  EMPLOYEE_TYPE_EMPLOYEE,
  EMPLOYEE_TYPE_PARTNER,
  PLACED,
  STAFFING_ROLES,
} from '@/store/constants.js'

dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)

export const ENTITIES = {
  ACTIVE_CLIENTS_BY_ID: 'activeClientsById',
  CLIENTS: 'clients',
  CLIENTS_BY_ID: 'clientsById',
  ENGAGEMENTS: 'engagements',
  ENGAGEMENTS_BY_ID: 'engagementsById',
  FEATURES: 'features',
  STAFFING_ROLES: STAFFING_ROLES,
  STAFFING_ROLES_BY_ID: 'staffingRolesById',
  PLACEMENTS: 'placements',
  RATINGS: 'ratings',
  SKILLS: 'skills',
  SPONSORSHIPS: 'sponsorships',
  SPONSORS: 'sponsors',
  USERS: 'users',
}

const { USERS } = ENTITIES

Vue.use(Vuex)

export const CONSULTANT_TIERS = new Set([0, 1, 2, 3, 4, 5, 6, 7])

export const NOT_PROPOSED = 'Not Proposed'
export const PROPOSED = 'Proposed'
export const STRIDER_CONFIRMED = 'Strider Confirmed'
export const CLIENT_CONFIRMED = 'Client Confirmed'

export const PLACEMENT_STATUSES = [
  NOT_PROPOSED,
  PROPOSED,
  STRIDER_CONFIRMED,
  CLIENT_CONFIRMED,
  PLACED,
]

export const PLACEMENT_STATUSES_ENUM = {
  NOT_PROPOSED,
  PROPOSED,
  STRIDER_CONFIRMED,
  CLIENT_CONFIRMED,
  PLACED,
}

export const NON_STAFFABLE_ENGAGEMENT_STATUSES = ['Disqualified', 'Closed - Lost']

const THIRTY = 30
const GRANULARITY_OF_LAST_AVAILABLE_DAY = 'day'

const isLastAvailableDayAfter30Days = (lastAvailableDay) => {
  const lastAvailableDayJs = dayjs(lastAvailableDay)
  const thirtyDaysFromNow = dayjs().add(THIRTY, GRANULARITY_OF_LAST_AVAILABLE_DAY)
  return lastAvailableDayJs.isAfter(thirtyDaysFromNow)
}

const onAClientOrLastAvailableDayFilter = (_getters, user) =>
  user.currentStaffingRoles.length > 0 ||
  !user.lastAvailableDay ||
  isLastAvailableDayAfter30Days(user.lastAvailableDay)

const lastAvailableDayWithClient = (lastAvailableDay, endDate) =>
  nextWeekdayAfter(dayjs.min(dayjs(lastAvailableDay), dayjs(endDate)))

export const getters = {
  getEntity: (state) => (entityName) => state[entityName],

  getEntityById: (state) => (entityName, id) => {
    if (state[entityName]?.length) {
      return state[entityName].find((entity) => entity.id === id)
    }
    return state[entityName][id]
  },

  getNextAvailableDateForUser: (_state, getterz) => (userId) => {
    const { currentStaffingRoles, lastAvailableDay } = getterz.getEntityById(ENTITIES.USERS, userId)
    const endDate = currentStaffingRoles?.map(
      (currentStaffingRole) => currentStaffingRole.endDate
    )[0]

    return endDate
      ? lastAvailableDay
        ? lastAvailableDayWithClient(lastAvailableDay, endDate)
        : nextWeekdayAfter(endDate)
      : dayjs().toDate()
  },

  activeUsers: (state) => {
    const users = {}
    for (const id in state.users) {
      const user = state.users[id]
      if (user.active) {
        users[id] = user
      }
    }
    return users
  },

  archivedUsers: (state) => {
    const users = {}
    for (const id in state.users) {
      const user = state.users[id]
      if (user.active === false) {
        users[id] = user
      }
    }
    return users
  },

  consultants: (_state, getterz) => {
    const users = {}
    for (const id in getterz.activeUsers) {
      const user = getterz.activeUsers[id]
      if (CONSULTANT_TIERS.has(user.tier)) {
        if (
          !user.lastAvailableDay ||
          dayjs().isSameOrBefore(user.lastAvailableDay, GRANULARITY_OF_LAST_AVAILABLE_DAY)
        ) {
          users[id] = user
        }
      }
    }
    return users
  },

  consultantsWithNextAvailableDate: (_state, getterz) =>
    Object.values(getterz.consultants).map((consultant) => ({
      ...consultant,
      nextAvailableDate: getterz.getNextAvailableDateForUser(consultant.id),
    })),

  consultantsWithAvailability: (_state, getterz) =>
    getterz.consultantsWithNextAvailableDate
      .filter(
        (consultant) =>
          consultant.percentageUtilization > 0 &&
          consultant.employeeType !== EMPLOYEE_TYPE_PARTNER &&
          onAClientOrLastAvailableDayFilter(getterz, consultant)
      )
      .map((consultant) => ({
        ...consultant,
        currentStaffingRole: consultant.currentStaffingRoles[0] || {},
        currentClientName: getterz.getCurrentClientNameByClientId(consultant.currentClientId),
      })),

  consultantsWithTypeOfEmployee: (_state, getterz) => {
    let consultants = []
    for (const id in getterz.consultants) {
      const consultant = getterz.consultants[id]
      if (consultant.employeeType === EMPLOYEE_TYPE_EMPLOYEE) {
        consultants = [...consultants, consultant]
      }
    }
    return consultants
  },

  currentUser: (state) => state.users[state.currentUserId],

  isAdmin: (_state, getterz) => getterz.currentUser.admin,

  unsponsored: (state, getterz) => {
    const sponsoredConsultantIds = state.sponsorships.map((sponsorship) => sponsorship.protegeId)
    const consultantsToBeUnsponsored = { ...getterz.consultants }
    sponsoredConsultantIds.forEach((id) => delete consultantsToBeUnsponsored[id])

    return Object.values(consultantsToBeUnsponsored)
  },
  ...engagements.getters,
  ...placements.getters,
  ...ratings.getters,
  ...skills.getters,
  ...staffingRoles.getters,
  ...sponsors.getters,
  ...sponsorships.getters,
  ...users.getters,
  ...clients.getters,
  ...features.getters,
}

export const actions = {
  async fetchUsers({ commit }) {
    const users = await api.fetchUsers()
    commit('set', {
      entityName: USERS,
      value: users,
    })
  },

  async deleteUser({ commit }, user) {
    try {
      await api.deleteUser(user)
      commit('delete', { entityName: USERS, id: user.id })

      _surfaceSuccessMessage('User successfully deleted!')
    } catch {
      _surfaceErrorMessage('User could not be deleted!')
    }
  },

  async toggleAdmin() {
    await api.toggleAdmin()
    window.location.reload()
  },

  async logout() {
    await api.logout()
    window.location.replace('login')
  },

  ...clients.actions,
  ...engagements.actions,
  ...features.actions,
  ...ratings.actions,
  ...staffingRoles.actions,
  ...skills.actions,
  ...sponsors.actions,
  ...sponsorships.actions,
  ...users.actions,
  ...placements.actions,
}

export const mutations = {
  set(state, { entityName, value }) {
    if (entityName === ENTITIES.USERS && value.length) {
      const entityObj = value.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {})
      state[entityName] = entityObj
    } else {
      state[entityName] = value
    }
  },

  insert(state, { entityName, value }) {
    if (entityName === ENTITIES.USERS) {
      state[entityName][value.id] = value
    } else {
      state[entityName].push(value)
    }
  },

  update(state, { entityName, id, updates }) {
    if (entityName === ENTITIES.USERS) {
      state[entityName][id] = { ...state[entityName][id], ...updates }
    } else {
      const updatedCollection = state[entityName].map((entity) =>
        entity.id === id ? { ...entity, ...updates } : entity
      )

      state[entityName] = updatedCollection
    }
  },

  delete(state, { entityName, id }) {
    if (entityName === ENTITIES.USERS) {
      const entities = { ...state[entityName] }
      delete entities[id]
      state[entityName] = entities
    } else {
      state[entityName] = state[entityName].filter((entityInstance) => entityInstance.id !== id)
    }
  },

  ...clients.mutations,
  ...engagements.mutations,
  ...features.mutations,
  ...placements.mutations,
  ...ratings.mutations,
  ...skills.mutations,
  ...staffingRoles.mutations,
  ...sponsors.mutations,
  ...sponsorships.mutations,
  ...users.mutations,
}

const createStore = () =>
  new Vuex.Store({
    state: {
      activeClientsById: {},
      users: {},
      currentUserId: window.currentUserId,
      sponsorships: [],
      sponsors: [],
      ratings: [],
      clients: [],
      clientsById: {},
      placements: [],
      engagements: [],
      engagementsById: {},
      featuresByKey: {},
      skills: [],
      staffingRoles: [],
      staffingRolesById: {},
      unsponsored: [],
    },
    getters,
    actions,
    mutations,
    strict: process.env.NODE_ENV === 'development',
  })

export default createStore
