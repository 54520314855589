import axios from 'axios'
import dayjs from 'dayjs'

const fetchCSRFToken = () => {
  const tag = document.querySelector('meta[name="csrf-token"]')
  return tag && tag.content ? tag.content : ''
}

axios.defaults.headers.post['X-CSRF-Token'] = fetchCSRFToken()
axios.defaults.headers.put['X-CSRF-Token'] = fetchCSRFToken()
axios.defaults.headers.delete['X-CSRF-Token'] = fetchCSRFToken()

const instance = axios.create({
  baseURL: window.location.origin,
  withCredentials: true,
})

const YYYYMMDDRegExp = /\d\d\d\d-\d\d-\d\d/

const recursivelyTransformDateStrings = (data) => {
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key]

      if (YYYYMMDDRegExp.test(value)) {
        data[key] = dayjs(value).toDate()
      } else if (typeof value === 'object') {
        recursivelyTransformDateStrings(value)
      }
    }
  }

  return data
}

export const dateCasterResponseInterceptor = (response) => {
  const { data } = response

  return {
    ...response,
    data: recursivelyTransformDateStrings(data),
  }
}

instance.interceptors.response.use(dateCasterResponseInterceptor, (error) =>
  Promise.reject(error.response)
)

const endpoints = {
  GET: {
    clients: (queryParams) => (queryParams ? `/clients?${queryParams}` : '/clients'),
    clientsById: () => '/clients/by_id',
    engagementsById: () => '/engagements',
    placements: () => '/placements',
    features: () => '/flipper/api/features',
    ratings: () => '/ratings',
    skills: () => '/skills',
    sponsorships: () => '/sponsorships',
    sponsors: () => '/sponsors',
    staffingRolesById: () => '/staffing_roles',
    syncWithHubspotDate: () => '/hubspot_sync_date',
    titles: () => '/users/titles',
    users: () => '/users',
    unsponsored: () => '/unsponsored',
  },
  POST: {
    clients: () => '/clients',
    engagements: (clientId) => `/clients/${clientId}/engagements`,
    sponsors: () => '/sponsors',
    sponsorships: () => '/sponsorships',
    staffingRoles: () => '/staffing_roles',
    users: () => '/users/',
  },
  PUT: {
    clients: (id) => `/clients/${id}`,
    engagements: (clientId, id) => `/clients/${clientId}/engagements/${id}`,
    ratings: (id) => `/ratings/${id}`,
    sponsorships: (id) => `/sponsorships/${id}`,
    sponsors: (id) => `/sponsors/${id}`,
    staffingRoles: (id) => `/staffing_roles/${id}`,
    multipleStaffingRoles: () => '/staffing_roles',
    toggleAdmin: () => '/toggle_admin',
    users: (id) => `/users/${id}`,
  },
  DELETE: {
    engagements: (clientId, id) => `/clients/${clientId}/engagements/${id}`,
    logout: () => '/logout',
    sponsors: (id) => `/sponsors/${id}`,
    sponsorships: (id) => `/sponsorships/${id}`,
    staffingRoles: (id) => `/staffing_roles/${id}`,
    users: (id) => `/users/${id}`,
  },
}

const fetchUsers = async () => {
  const response = await instance.get(endpoints.GET.users())
  const result = response.data
  return result.sort((u1, u2) => (u1.name > u2.name ? 1 : -1))
}

const toggleAdmin = async () => {
  await instance.put(endpoints.PUT.toggleAdmin())
}

const fetchSkills = async () => {
  const response = await instance.get(endpoints.GET.skills())
  return response.data
}

const fetchTitles = async () => {
  const response = await instance.get(endpoints.GET.titles())
  return response.data
}

const fetchEngagementsById = async () => (await instance.get(endpoints.GET.engagementsById())).data

const fetchFeatures = async () => (await instance.get(endpoints.GET.features())).data

const fetchStaffingRolesById = async () =>
  (await instance.get(endpoints.GET.staffingRolesById())).data

const fetchActiveStaffingRolesById = async () =>
  (
    await instance.get(
      constructUrl(endpoints.GET.staffingRolesById(), {
        'active-only': true,
      })
    )
  ).data

const fetchClientsById = async () => (await instance.get(endpoints.GET.clientsById())).data

const fetchClients = async (clientEndpoint = endpoints.GET.clients()) => {
  const response = await instance.get(clientEndpoint)
  return response.data
}

const fetchActiveClientsById = async () =>
  (
    await instance.get(
      constructUrl(endpoints.GET.clientsById(), {
        'active-only': true,
      })
    )
  ).data

const fetchPlacements = async () => (await instance.get(endpoints.GET.placements())).data

const saveClient = async (data) => {
  const response = await instance.post(endpoints.POST.clients(), data)
  return response.data
}

const updateClient = async ({ id, ...params }) => {
  const url = endpoints.PUT.clients(id)
  const response = await instance.put(url, params)
  return response.data
}

const saveEngagement = async ({ clientId, ...engagement }) => {
  const endpoint = endpoints.POST.engagements(clientId)
  const response = await instance.post(endpoint, engagement)
  return response.data
}

const createStaffingRole = async ({
  engagementId,
  startDate,
  endDate,
  description,
  tier,
  team,
  rate,
  roleFunction,
  skillIds,
  percentageUtilization,
  placementsAttributes,
  managerId,
}) => {
  const response = await instance.post(endpoints.POST.staffingRoles(), {
    engagementId,
    startDate,
    endDate,
    description,
    tier,
    team,
    rate,
    roleFunction,
    skillIds,
    percentageUtilization,
    placementsAttributes,
    managerId,
  })

  return response.data
}

const deleteStaffingRole = async (id) => {
  await instance.delete(endpoints.DELETE.staffingRoles(id))
}

const deleteUser = async ({ id }) => {
  await instance.delete(endpoints.DELETE.users(id))
}

const updateUser = async ({ id, ...params }) => {
  const url = endpoints.PUT.users(id)
  const response = await instance.put(url, params)
  return response.data
}

const createUser = async ({ ...params }) => {
  const url = endpoints.POST.users()
  const response = await instance.post(url, params)
  return response.data
}

const deleteEngagement = async ({ clientId, id }) => {
  const endpoint = endpoints.DELETE.engagements(clientId, id)
  return instance.delete(endpoint)
}

const updateStaffingRole = async ({ staffingRoleId, ...params }) => {
  const url = endpoints.PUT.staffingRoles(staffingRoleId)
  const response = await instance.put(url, params)
  return response.data
}

const bulkUpdateStaffingRole = async (params) => {
  const url = endpoints.PUT.multipleStaffingRoles()
  const response = await instance.put(url, params)
  return response.data
}

const fetchSponsorships = async () => {
  const response = await instance.get(endpoints.GET.sponsorships())
  return response.data
}

const fetchSponsors = async () => {
  const response = await instance.get(endpoints.GET.sponsors())
  return response.data.map((sponsor) => transformSponsor(sponsor))
}

const fetchRatings = async () => {
  const response = await instance.get(endpoints.GET.ratings())
  return response.data
}

const updateRating = async ({ id, ...params }) => {
  const url = endpoints.PUT.ratings(id)
  const response = await instance.put(url, params)
  return response.data
}

const updateEngagement = async ({ clientId, id, ...params }) => {
  const url = endpoints.PUT.engagements(clientId, id)
  const response = await instance.put(url, params)
  return response.data
}

const saveSponsorship = async (details) => {
  const d = details || {}
  const response = await instance.post(endpoints.POST.sponsorships(), {
    sponsorId: d.sponsorId,
    protegeId: d.protegeId,
    onboarding: d.onboarding || false,
    sendEmail: d.sendEmail || false,
  })
  return response.data
}

const updateSponsorship = async (details) => {
  const d = details || {}
  const url = endpoints.PUT.sponsorships(details.id)
  const response = await instance.put(url, {
    onboarding: d.onboarding || false,
    sendEmail: d.sendEmail || false,
  })
  return response.data
}

const deleteSponsorship = async (sponsorship) => {
  const url = endpoints.DELETE.sponsorships(sponsorship.id)
  const response = await instance.delete(url, {})
  return response.data
}

const saveSponsor = async (details) => {
  const d = details || {}
  const response = await instance.post(endpoints.POST.sponsors(), {
    userId: d.userId,
    limit: d.limit || 3,
    onboarding: d.onboarding || false,
  })
  return response.data
}

const updateSponsor = async (details) => {
  const d = details || {}
  const url = endpoints.PUT.sponsors(details.id)
  const response = await instance.put(url, {
    limit: d.limit || 3,
    onboarding: d.onboarding || false,
  })
  return response.data
}

const deleteSponsor = async (details) => {
  const d = details || {}
  const url = endpoints.DELETE.sponsors(d.id)
  const response = await instance.delete(url, {})
  return response.data
}

const transformSponsor = ({ active = false, limit = 0, onboarding = false, ...rest }) => ({
  active,
  limit,
  onboarding,
  ...rest,
})

const logout = async () => {
  await instance.delete(endpoints.DELETE.logout())
}

const constructUrl = (path, queryParams) => {
  const url = new URL(path, window.location.origin)
  Object.keys(queryParams).forEach((key) => url.searchParams.set(key, queryParams[key]))
  return url.pathname + url.search
}

const getSyncHubspotDate = async () => {
  const response = await instance.get(endpoints.GET.syncWithHubspotDate())
  const date = response?.data?.last_success
  return date ? new Date(date).toLocaleString() : 'Cannot find last successful date!'
}

export default {
  fetchUsers,
  toggleAdmin,
  deleteUser,
  updateUser,
  createUser,
  fetchSkills,
  fetchTitles,
  fetchSponsorships,
  fetchSponsors,
  fetchRatings,
  fetchEngagementsById,
  fetchFeatures,
  fetchStaffingRolesById,
  fetchActiveStaffingRolesById,
  fetchClientsById,
  fetchClients,
  fetchActiveClientsById,
  fetchPlacements,
  saveClient,
  saveEngagement,
  deleteStaffingRole,
  createStaffingRole,
  updateStaffingRole,
  bulkUpdateStaffingRole,
  deleteEngagement,
  updateRating,
  saveSponsorship,
  updateClient,
  updateSponsorship,
  updateEngagement,
  deleteSponsorship,
  saveSponsor,
  updateSponsor,
  deleteSponsor,
  logout,
  instance,
  getSyncHubspotDate,
}
