import api from '@/api'
import { _surfaceErrorMessage, _surfaceSuccessMessage } from '@/snackbar'

const actions = {
  async createStaffingRole({ commit }, staffingRole) {
    try {
      const { placements: newPlacements, ...persistedStaffingRole } = await api.createStaffingRole(
        staffingRole
      )
      newPlacements.forEach((placement) => commit('insertPlacement', placement))

      commit('insertStaffingRole', persistedStaffingRole)
      _surfaceSuccessMessage('Staffing role added!')
    } catch {
      _surfaceErrorMessage('Save failed! Please try again later.')
    }
  },
  async deleteStaffingRole({ commit }, staffingRoleId) {
    try {
      await api.deleteStaffingRole(staffingRoleId)

      commit('deleteStaffingRole', staffingRoleId)
      commit('removePlacements', staffingRoleId)
      _surfaceSuccessMessage('Staffing role deleted!')
    } catch {
      _surfaceErrorMessage('Could not be deleted!')
    }
  },
  async updateStaffingRole({ commit, state }, payload) {
    const oldPlacements = state.placements

    try {
      const { placements: newPlacements, ...staffingRole } = await api.updateStaffingRole(payload)
      const updatedPlacements = [
        ...oldPlacements.filter((placement) => placement.staffingRoleId !== staffingRole.id),
        ...newPlacements,
      ]

      commit('setPlacements', updatedPlacements)

      commit('updateStaffingRole', {
        id: staffingRole.id,
        updates: staffingRole,
      })
      _surfaceSuccessMessage('Staffing role updated!')
    } catch (payload) {
      const { error } = payload.data
      _surfaceErrorMessage(`Staffing role not updated: ${error}`)
    }
  },
  async bulkUpdateStaffingRole({ commit }, payload) {
    try {
      const updatedStaffingRoles = await api.bulkUpdateStaffingRole(payload)

      const { ids } = payload
      commit('bulkUpdateStaffingRole', { ids, updates: updatedStaffingRoles })

      _surfaceSuccessMessage('Staffing role(s) updated!')
    } catch (errorObject) {
      const { error } = errorObject.data
      _surfaceErrorMessage(`Staffing role(s) not updated: ${error}`)
    }
  },

  fetchStaffingRolesById: async ({ commit }) =>
    commit('setStaffingRolesById', await api.fetchStaffingRolesById()),

  fetchActiveStaffingRolesById: async ({ commit }) =>
    commit('setStaffingRolesById', await api.fetchActiveStaffingRolesById()),
}

export default actions
