const getters = {
  sponsorsWithUsers: ({ sponsors, users }, getters) =>
    sponsors
      .map((sponsor) => {
        const user = users[sponsor.userId]
        // eslint-disable-next-line no-unused-vars
        const { userId, ...sponsorWithoutUserId } = sponsor

        const available = sponsor.active && getters.isSponsorAvailable(sponsor)
        return { user, available, ...sponsorWithoutUserId }
      })
      .sort((a, b) => (a.user.name < b.user.name ? -1 : 1)),

  isSponsor: ({ sponsors, currentUserId }) =>
    sponsors.map((sponsor) => sponsor.userId).includes(currentUserId),

  isSponsorAvailable: (_, getters) => (sponsor) => {
    const amountOfSponsorships = getters.getSponsorshipsFromUserId(sponsor.userId).length
    return amountOfSponsorships < sponsor.limit
  },
}

export default getters
