import sortBy from 'lodash/sortBy'

import { ENTITIES } from '@/store'

const getSkillNameFromId = (_, getters) => (skillId) =>
  getters.getEntityById(ENTITIES.SKILLS, skillId).name

const sortSkillsByName = (_, getters) => () =>
  sortBy(getters.getEntity(ENTITIES.SKILLS), (skill) => skill.name.toLowerCase())

export default {
  getSkillNameFromId,
  sortSkillsByName,
}
