import dayjs from 'dayjs'
import { ENTITIES } from '@/store'
import { PLACED } from '@/store/constants.js'

const clientGetters = {
  getFutureProposedClientsForUser: (state, _) => (userId) =>
    state[ENTITIES.PLACEMENTS]
      .filter((placement) => placement.userId === userId)
      .filter(
        (placement) =>
          placement.status !== PLACED ||
          dayjs(state[ENTITIES.STAFFING_ROLES_BY_ID][placement.staffingRoleId]?.startDate).isAfter(
            dayjs(),
            'day'
          )
      )
      .filter(
        (placement) =>
          state[ENTITIES.ENGAGEMENTS_BY_ID][
            state[ENTITIES.STAFFING_ROLES_BY_ID][placement.staffingRoleId]?.engagementId
          ]?.likelihood > 0
      )
      .map((placement) => ({
        clientName:
          state[ENTITIES.CLIENTS_BY_ID][
            state[ENTITIES.ENGAGEMENTS_BY_ID][
              state[ENTITIES.STAFFING_ROLES_BY_ID][placement.staffingRoleId]?.engagementId
            ]?.clientId
          ]?.name,
        status: placement.status,
        likelihood:
          state[ENTITIES.ENGAGEMENTS_BY_ID][
            state[ENTITIES.STAFFING_ROLES_BY_ID][placement.staffingRoleId]?.engagementId
          ]?.likelihood,
        startDate: dayjs(
          state[ENTITIES.STAFFING_ROLES_BY_ID][placement.staffingRoleId]?.startDate
        ).format('MM/DD/YY'),
        endDate: dayjs(
          state[ENTITIES.STAFFING_ROLES_BY_ID][placement.staffingRoleId]?.endDate
        ).format('MM/DD/YY'),
      })) || [],

  getActiveClients: (state, _) => () =>
    state[ENTITIES.CLIENTS].filter((client) => !client.archived),
  getArchivedClients: (state, _) => () =>
    state[ENTITIES.CLIENTS].filter((client) => client.archived),

  getCurrentClientNameByClientId: (_, getters) => (clientId) => {
    const currentClient = getters.getEntityById(ENTITIES.CLIENTS, clientId)
    return currentClient ? currentClient.name : 'Bench'
  },

  getListOfPastClientsForUser: (_, getters) => (consultantId) => {
    const staffingRoles = getters.getAllPastPlacedStaffingRolesByConsultantId(consultantId)

    const pastClients = staffingRoles.map((role) => {
      const engagement = getters.getEntityById(ENTITIES.ENGAGEMENTS, role.engagementId)
      const { name } = getters.getEntityById(ENTITIES.CLIENTS, engagement.clientId)

      const { id, startDate, endDate } = role

      return { id, name, startDate, endDate }
    })

    return pastClients.sort(compareByEndDateDesc)
  },
}

function compareByEndDateDesc(first, second) {
  if (first.endDate < second.endDate) {
    return 1
  }

  if (second.endDate < first.endDate) {
    return -1
  }

  return 0
}

export default clientGetters
