import orderBy from 'lodash/orderBy'
import dayjs from 'dayjs'
import { ACTIVE_ENGAGEMENT_STATUSES, PLACED } from '@/store/constants'
import { amountOfBusinessDays, FULL_DAY_IN_HOURS } from '@/helpers/dateHelper'

const AMOUNT_OF_DAYS_TO_FILTER_INACTIVE_ENGAGEMENTS = 10
const getters = {
  filterEngagementsByClientId: (state, _) => (clientId) =>
    state.engagements.filter((engagement) => engagement.clientId === clientId),

  getSalesLeadUserNamesForEngagement: (state) => (engagement) => {
    if (!engagement.salesLeadUserIds) return []
    return engagement.salesLeadUserIds.map((id) => state.users[id].name)
  },

  getEngagementLeadUserNamesForEngagement: (state) => (engagement) => {
    if (!engagement.engagementLeadUserIds) return []
    return engagement.engagementLeadUserIds.map((id) => state.users[id].name)
  },

  getManagersForEngagement: (state) => (engagement) => {
    if (!engagement.managerIds) return []
    return engagement.managerIds.map((id) => state.users[id])
  },

  getEngagementsForClientView:
    (_, getters) =>
    (clientId, isShowingAllEngagements = false) => {
      const clientEngagements = isShowingAllEngagements
        ? getters.filterEngagementsByClientId(clientId)
        : getters.filterActiveEngagementsByClientId(clientId)
      const clientEngagementsWithSalesLeadUserNames = clientEngagements.map((engagement) => {
        const salesLeadUserNames = getters.getSalesLeadUserNamesForEngagement(engagement)
        const engagementLeadUserNames = getters.getEngagementLeadUserNamesForEngagement(engagement)
        const managerNames = getters.getManagersForEngagement(engagement).map(({ name }) => name)
        return {
          ...engagement,
          salesLeadUserNames,
          engagementLeadUserNames,
          managerNames,
        }
      })

      return orderBy(clientEngagementsWithSalesLeadUserNames, 'id', 'desc')
    },

  canManageEngagement:
    (state, _, { currentUserId }, { isAdmin }) =>
    (engagementId) =>
      (state.engagementsById[engagementId] || false) &&
      (isAdmin ||
        state.engagementsById[engagementId]?.engagementLeadUserIds.includes(currentUserId) ||
        state.engagementsById[engagementId]?.managerIds.includes(currentUserId)),

  filterActiveEngagementsByClientId: (_, getters) => (clientId) =>
    getters
      .filterEngagementsByClientId(clientId)
      .filter(
        (e) =>
          ACTIVE_ENGAGEMENT_STATUSES.includes(e.status) ||
          dayjs(e.statusUpdatedAt).isAfter(
            dayjs().subtract(AMOUNT_OF_DAYS_TO_FILTER_INACTIVE_ENGAGEMENTS, 'day')
          )
      )
      .filter((e) => getters.isEngagementInProgress(e.id)),

  isEngagementInProgress: (_, getters) => (engagementId) => {
    const staffingRoles = getters.getStaffingRolesByEngagementId(engagementId)

    const placements = staffingRoles.map((staffingRole) =>
      getters.getPlacementsForStaffingRole(staffingRole.id)
    )

    return (
      staffingRoles.length === 0 ||
      placements.some((placement) => placement.length === 0) ||
      staffingRoles.some((staffingRole) => dayjs().isBefore(dayjs(staffingRole.endDate))) ||
      placements.flat().some((placement) => placement.status !== PLACED)
    )
  },

  getStaffingEstimatedRevenueAmount: (_, getters) => (engagementId) => {
    const staffingRoles = getters.getStaffingRolesByEngagementId(engagementId)
    if (staffingRoles.length === 0) {
      return 0
    }

    return staffingRoles.reduce(
      (acc, sr) =>
        acc +
        sr.rate *
          ((sr.percentageUtilization / 100) * FULL_DAY_IN_HOURS) *
          amountOfBusinessDays(dayjs(sr.startDate), dayjs(sr.endDate)),
      0
    )
  },
}

export default getters
