const mutations = {
  setClientsById: (state, clientsById) => {
    state.clientsById = clientsById
  },

  setActiveClientsById: (state, activeClientsById) => {
    state.activeClientsById = activeClientsById
  },

  setClients(state, clients) {
    state.clients = clients
  },

  insertClient(state, newClient) {
    state.clients.push(newClient)
  },

  updateClient(state, { id, updates }) {
    state.clients = state.clients.map((entity) =>
      entity.id === id ? { ...entity, ...updates } : entity
    )
  },
}

export default mutations
