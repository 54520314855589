import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const store = {
  mutations,
  actions,
  getters,
}
export default store
