import api from '@/api'

const actions = {
  async fetchSponsors({ commit }) {
    const sponsors = await api.fetchSponsors()
    commit('setSponsors', sponsors)
  },

  async saveNewSponsor({ dispatch }, sponsorDetails) {
    await api.saveSponsor(sponsorDetails)
    dispatch('fetchSponsors')
  },

  async updateSponsor({ dispatch }, sponsorDetails) {
    await api.updateSponsor(sponsorDetails)
    dispatch('fetchSponsors')
  },

  async deleteSponsor({ dispatch }, sponsorDetails) {
    await api.deleteSponsor(sponsorDetails)
    dispatch('fetchSponsors')
    dispatch('fetchSponsorships')
  },
}

export default actions
