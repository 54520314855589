import Vue from 'vue'
import Router from 'vue-router'

import Index from './views/Index.vue'

const ExternalServices = () => import(/* webpackChunkName: "user" */ './views/ExternalServices.vue')
const Team = () => import(/* webpackChunkName: "user" */ './views/Team.vue')
const Clients = () => import(/* webpackChunkName: "user" */ './views/Clients.vue')
const Client = () => import(/* webpackChunkName: "user" */ './views/Client.vue')
const Staffing = () => import(/* webpackChunkName: "user" */ './views/Staffing.vue')
const UserProfile = () => import(/* webpackChunkName: "user" */ './views/UserProfile.vue')
const Sponsorships = () => import(/* webpackChunkName: "user" */ './views/Sponsorships.vue')
const Managers = () => import(/* webpackChunkName: "user" */ './views/Managers.vue')
const Documents = () => import(/* webpackChunkName: "user" */ './views/Documents.vue')
const SponsorshipForm = () => import(/* webpackChunkName: "user" */ './views/SponsorshipForm.vue')
const SponsorForm = () => import(/* webpackChunkName: "user" */ './views/SponsorForm.vue')
const Availability = () => import(/* webpackChunkName: "user" */ './views/Availability.vue')
const NotFound = () => import(/* webpackChunkName: "user" */ './views/404.vue')

Vue.use(Router)

const createRouter = () => {
  const routes = [
    {
      path: '/',
      component: Index,
      redirect: '/availability',
      children: [
        { path: '/availability', component: Availability },
        { path: '/external-services', component: ExternalServices },
        { path: '/team', component: Team },
        {
          path: '/team/:userId',
          component: UserProfile,
          props: (route) => ({ userId: parseInt(route.params.userId, 10) }),
        },
        { path: '/clients', component: Clients },
        {
          path: '/clients/:clientId',
          component: Client,
          props: (route) => ({ clientId: parseInt(route.params.clientId, 10) }),
        },
        { path: '/staffing', component: Staffing },
        { path: '/sponsorships', component: Sponsorships },
        { path: '/managers', component: Managers },
        { path: '/documents', component: Documents },
        { path: '/sponsorships/new', component: SponsorshipForm },
        {
          path: '/sponsorships/:sponsorshipId',
          component: SponsorshipForm,
          props: (route) => ({ sponsorshipId: parseInt(route.params.sponsorshipId, 10) }),
        },
        { path: '/sponsorships/sponsors/new', component: SponsorForm },
        {
          path: '/sponsorships/sponsors/:sponsorId',
          component: SponsorForm,
          props: (route) => ({ sponsorId: parseInt(route.params.sponsorId, 10) }),
        },
        {
          path: '/404',
          name: 'NotFound',
          component: NotFound,
        },
      ],
    },
    { path: '*', redirect: '/404' },
  ]

  const router = new Router({
    base: process.env.BASE_URL,
    routes,
  })

  return router
}

export default createRouter
