import api from '@/api'
import { _surfaceErrorMessage, _surfaceSuccessMessage } from '@/snackbar'

const actions = {
  fetchEngagementsById: async ({ commit }) =>
    commit('setEngagementsById', await api.fetchEngagementsById()),

  async saveEngagement({ commit }, data) {
    try {
      const newEngagement = await api.saveEngagement(data)

      commit('insertEngagement', newEngagement)

      _surfaceSuccessMessage('Added!')
    } catch {
      _surfaceErrorMessage('Creation failed! Please try again.')
    }
  },
  async deleteEngagement({ commit }, engagement) {
    try {
      await api.deleteEngagement(engagement)

      commit('deleteEngagement', engagement.id)

      _surfaceSuccessMessage('Successfully deleted!')
    } catch {
      _surfaceErrorMessage('Could not be deleted!')
    }
  },
  async updateEngagement({ commit }, engagementParams) {
    try {
      commit('updateEngagement', {
        id: engagementParams.id,
        updates: { updateInFlight: true },
      })

      const updatedEngagement = await api.updateEngagement(engagementParams)
      commit('updateEngagement', {
        id: updatedEngagement.id,
        updates: { ...updatedEngagement, inEditState: false, updateInFlight: false },
      })

      _surfaceSuccessMessage('Updated!')
    } catch {
      _surfaceErrorMessage('Update failed! Please try again.')
      commit('updateEngagement', {
        id: engagementParams.id,
        updates: { updateInFlight: false },
      })
    }
  },
}

export default actions
