import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

const [SUNDAY, FRIDAY, SATURDAY, NEXT_MONDAY] = [0, 5, 6, 8]
const WEEKEND = [SATURDAY, SUNDAY]

export const nextWeekdayAfter = (date) => {
  if (date === null) {
    throw new Error('Invalid Date')
  }

  const d = dayjs(date)
  if ([FRIDAY, SATURDAY].includes(d.day())) {
    return d.day(NEXT_MONDAY).toDate()
  }
  return d.add(1, 'day').toDate()
}

export const amountOfBusinessDays = (startDate, endDate) => {
  let beginDate = startDate
  let amountOfDays = 0

  while (beginDate.isBetween(startDate, endDate, 'day', '[]')) {
    if (!WEEKEND.includes(beginDate.day())) {
      amountOfDays += 1
    }
    beginDate = beginDate.add(1, 'day')
  }

  return amountOfDays
}

export const FULL_DAY_IN_HOURS = 8
