<template>
  <header v-bind:class="{ 'header--staging': isStaging }">
    <div class="header__left">
      <router-link v-if="currentUser" :to="`/team/${currentUser.id}`" class="profile-link">
        <i class="fas fa-user is-size-4 profile-link__icon"></i>
        <span class="is-size-4 has-text-weight-semibold">My Profile</span>
      </router-link>
    </div>
    <span class="has-text-black is-size-5 has-text-weight-medium header__center" v-if="isStaging">
      STAGING
    </span>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import UserWidget from '@/components/UserWidget.vue'
import * as Environment from '@/helpers/environmentHelper'

export default {
  components: {
    UserWidget,
  },
  mounted() {
    this.$analytics.identify(this.currentUser)
  },
  computed: {
    ...mapGetters(['currentUser']),
    isStaging: () => Environment.isStaging(),
  },
}
</script>

<style scoped lang="scss">
.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include for-desktop-up {
    background-color: $dark-green;
  }

  &__left {
    flex: 1;
  }

  &__center {
    flex: 1;
    margin-left: -85px;

    @include for-desktop-up {
      margin-left: -295px;
    }
  }

  &--staging {
    background-color: #fddd5c;
  }
}

.profile-link {
  padding: 1rem;
  color: $cream;

  &__icon {
    margin-right: 0.5rem;
  }
}
</style>
