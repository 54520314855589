<template>
  <div class="user-widget">
    <span v-if="showName" class="user-name" data-name>{{ user.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.user-widget {
  display: flex;
  align-items: center;

  .user-name {
    margin-left: 1rem;
  }

  @include for-desktop-up {
    margin-right: 2rem;
  }
}
</style>
