import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {
  CLOSED_WON_ENGAGEMENT_STATUSES,
  ENGAGEMENTS,
  PLACED,
  PLACEMENTS,
  SKILLS,
  STAFFING_ROLES,
  USERS,
} from '@/store/constants'

import { PLACEMENT_STATUSES_ENUM, ENTITIES } from '@/store'

dayjs.extend(isBetween)
const getters = {
  getFilteredRolesForStaffingViewById: (state) =>
    Object.values(state[ENTITIES.STAFFING_ROLES_BY_ID]).filter(
      (role) =>
        state[PLACEMENTS].find((p) => p.staffingRoleId === role.id)?.status !==
          PLACEMENT_STATUSES_ENUM.PLACED || dayjs(role.startDate).isAfter(dayjs())
    ),

  getCurrentPlacedStaffingRoleByConsultantId: (state) => (consultantId) => {
    const staffingRolesForPlacements = _getAllPlacedStaffingRolesForAGivenConsultant(
      state,
      consultantId
    )

    return staffingRolesForPlacements.find(_isStaffingRoleCurrent)
  },

  getAllPastPlacedStaffingRolesByConsultantId: (state) => (consultantId) => {
    const pastStaffingRoles = _getAllPlacedStaffingRolesForAGivenConsultant(state, consultantId)

    return pastStaffingRoles.filter((role) => {
      const engagement = state[ENGAGEMENTS].find((e) => e.id === role.engagementId)

      return (
        CLOSED_WON_ENGAGEMENT_STATUSES.includes(engagement?.status) &&
        dayjs().isAfter(dayjs(role.endDate))
      )
    })
  },

  currentPlacedStaffingRoles: (state) => {
    const placedPlacements = state[PLACEMENTS].filter((placement) => placement.status === PLACED)

    const staffingRolesForPlacements = placedPlacements.flatMap((placement) =>
      state[STAFFING_ROLES].filter((role) => placement.staffingRoleId === role.id)
    )

    return staffingRolesForPlacements.filter(_isStaffingRoleCurrent)
  },

  buildCommaDelimitedSkillNames: (_, getters) => (staffingRole) => {
    const skillNames = staffingRole.skillIds.map((skillId) => {
      const skill = getters.getEntityById(SKILLS, skillId)

      return skill ? skill.name : ''
    })
    return skillNames.join(', ')
  },

  staffingRolesAugmentedFor: (_, getters) => (engagementId) => {
    const staffingRoleForEngagement = getters
      .getEntity(STAFFING_ROLES)
      .filter((role) => role.engagementId === engagementId)

    return staffingRoleForEngagement.map((staffingRole) => ({
      ...staffingRole,
      managerName: _managerNames(staffingRole, getters.getEntityById),
      allPossiblePlacements: getters.buildAllPossiblePlacementsForStaffingRole(staffingRole),
      skillNamesCommaDelimited: getters.buildCommaDelimitedSkillNames(staffingRole),
    }))
  },

  getStaffingRolesByEngagementId: (_, getters) => (engagementId) =>
    getters.getEntity(STAFFING_ROLES).filter((s) => s.engagementId === engagementId),
}

const _getAllPlacedStaffingRolesForAGivenConsultant = (state, consultantId) => {
  const consultantPlacements = state[PLACEMENTS].filter(
    (placement) => placement.userId === consultantId && placement.status === PLACED
  )

  return consultantPlacements.reduce((result, placement) => {
    const role = state[STAFFING_ROLES].find((role) => role.id === placement.staffingRoleId)
    if (role) {
      result.push(role)
    }
    return result
  }, [])
}

const _isStaffingRoleCurrent = (role) =>
  dayjs().isBetween(role.startDate, role.endDate, 'day', '[]')

const _managerNames = (staffingRole, getEntityById) =>
  staffingRole.managerId ? getEntityById(USERS, staffingRole.managerId).name : null

export default getters
