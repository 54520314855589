import dayjs from 'dayjs'

const DATE_FORMAT_MONTH_DAY_YEAR = 'MM/DD/YYYY'

export const formatDate = (date) => dayjs(date).format(DATE_FORMAT_MONTH_DAY_YEAR)

export default {
  name: 'formatDate',
  func: formatDate,
}
