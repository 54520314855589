function formatCurrency(amount) {
  if (!amount) return ''
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formatter.format(amount)
}

export default {
  name: 'formatCurrency',
  func: formatCurrency,
}
