import { SKILLS } from '@/store/constants'

const getRatingsForUserId = (state, _) => (userId) =>
  state.ratings.filter((rating) => rating.userId === userId)

const getRatingsForSkillId = (state, _) => (skillId) =>
  state.ratings.filter((rating) => rating.skillId === skillId)

const getRatingProficiencyForSkillIdAndUserId = (_, getters) => (skillId, userId) => {
  const ratingsForSkillId = getters.getRatingsForSkillId(skillId)
  const userWithRatingForSkill = ratingsForSkillId.find((rating) => rating.userId === userId)
  return userWithRatingForSkill ? userWithRatingForSkill.proficiency : null
}

const getSkillRatingsForUser = (_, getters) => (userId) => {
  const relevantRatings = getters.getRatingsForUserId(userId)

  return relevantRatings.map((rating) => {
    const skill = getters.getEntityById(SKILLS, rating.skillId)
    return { ...rating, skill }
  })
}

export default {
  getRatingsForUserId,
  getRatingsForSkillId,
  getRatingProficiencyForSkillIdAndUserId,
  getSkillRatingsForUser,
}
