const mutations = {
  updateUser(state, { id, updates }) {
    const updatedUser = { ...state.users[id], ...updates }

    state.users[id] = updatedUser
  },

  insertUser(state, user) {
    const users = { ...state.users, [user.id]: user }

    state.users = users
  },

  setTitles(state, titles) {
    state.titles = titles
  },
}

export default mutations
